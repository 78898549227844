import { createSlice } from "@reduxjs/toolkit";
import { CartJson } from "@/types/Cart.type";

type InitialState = {
	orders: CartJson[];
};
const initialState: InitialState = {
	orders: [],
};

const OrderSlice = createSlice({
	name: "order",
	initialState: initialState,
	reducers: {
		setListOrder: (state, action) => {
			state.orders = action.payload;
		},
	},
});

export const { setListOrder } = OrderSlice.actions;

export default OrderSlice.reducer;
