import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import ShopReducer from "./features/ShopSlice";
import CartReducer from "./features/CartSlice";
import CustomerReducer from "./features/CustomerSlice";
import LoyaltyReducer from "./features/LoyaltySlice";
import OrderReducer from "./features/OrderSlice";
import PromotionReducer from "./features/PromotionSlice";

export const store = configureStore({
	reducer: {
		ShopReducer,
		CartReducer,
		CustomerReducer,
		LoyaltyReducer,
		OrderReducer,
		PromotionReducer,
	},
	devTools: process.env.NODE_ENV !== "production",
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false}),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
