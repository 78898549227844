"ues client";
import { AccountJson } from "@/types/Auth.type";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Local from "@/common/constants/local";

type InitialState = {
  token: string | null;
  showLogin: boolean;
  user: AccountJson | null;
  errors: string[];
  saveSignIn: boolean;
  password: string;
  callbackUrl: string;
};
const userLocalStorage =
  typeof window !== "undefined"
    ? localStorage.getItem(Local.storage.user)
    : null;
const initialState: InitialState = {
  showLogin: false,
  token: null,
  user:null,
  errors: [],
  saveSignIn: true,
  password: "",
  callbackUrl: "",
};
const LoyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem(Local.storage.token, JSON.stringify(action.payload));
    },
    clearToken: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem(Local.storage.token,);
      localStorage.removeItem(Local.storage.user);
    },
    toggleShowLogin: (state) => {
      state.showLogin = !state.showLogin;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem(Local.storage.user, JSON.stringify(action.payload));
    },

    setSaveSignInAuth: (state, action) => {
      state.saveSignIn = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    /////////////////////////////
    setErrorsAuth: (
      state,
      { payload: { errors } }: { payload: { errors: string[] } }
    ) => {
      state.errors = errors;
    },
    setCallbackUrl: (state, action) => {
      state.callbackUrl = action.payload;
    },
    ////
  },
});

export const {
  setToken,
  toggleShowLogin,
  clearToken,
  setUser,
  setErrorsAuth,
  setSaveSignInAuth,
  setPassword,
  setCallbackUrl,
} = LoyaltySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToken = (state: RootState) => state.LoyaltyReducer.token;
export const selectUser = (state: RootState) => state.LoyaltyReducer.user;
export const selectCallbackUrl = (state: RootState) =>
  state.LoyaltyReducer.callbackUrl;

export default LoyaltySlice.reducer;
