"use client";
import { useAppDispatch } from "@/redux/hook";
import { useEffect } from "react";
import { Toaster } from "sonner";
import {
  getDataFromLocalStorage,
  setDataLanguage,
  setDevice,
  setLanguage,
  setSystemSetting,
} from "@/redux/features/ShopSlice";
import { Device, SystemSetting } from "@/types/Shop.type";
import { LanguageSetting } from "@/common/constants/language";
import { DataLanguageCache } from "@/common/types/SiteLanguage.type";
import Local from "@/common/constants/local";
import Helper from "@/utils/helper";
import useStoreId from "@/lib/hooks/useStoreId";
import dynamic from "next/dynamic";
import Aos from "aos";

const FloatButtonPage = dynamic(() => import("../FloatButtonPage"), {
  ssr: false,
});
const PopupsPage = dynamic(() => import("../PopupsPage"), { ssr: false });

export default function InitProvider({
  children,
  systemSetting,
  device,
}: {
  children: React.ReactNode;
  systemSetting: SystemSetting;
  device: Device;
}) {
  const dispatch = useAppDispatch();
  const data = useStoreId(systemSetting.store_id);

  function init() {
    const lang = systemSetting || LanguageSetting[0];
    dispatch(setSystemSetting(systemSetting));
    dispatch(setLanguage(systemSetting));

    // set Device
    dispatch(setDevice(device));

    // cache language setting
    const dataLocal = getDataFromLocalStorage<DataLanguageCache>(
      Local.cookies.language
    );
    const dataClear = {
      space: [],
      data: {},
      lang: lang.lang,
      version: lang.version,
    };
    if (dataLocal) {
      if (dataLocal.lang !== lang.lang || dataLocal.version !== lang.version) {
        if (!Helper.isServer()) {
          localStorage.setItem(
            Local.cookies.language,
            JSON.stringify(dataClear)
          );
        }
      } else {
        dispatch(setDataLanguage(dataLocal));
      }
    } else {
      localStorage.setItem(Local.cookies.language, JSON.stringify(dataClear));
    }
  }
  useEffect(() => {
    init();
    Aos.init({ once: true });
  });



  return (
    <>
      {children}
      <PopupsPage />
      <FloatButtonPage />
      <Toaster duration={2000} position="top-center" richColors  offset={80}  />
    </>
  );
}
