"ues client";
import { createSlice } from "@reduxjs/toolkit";
import { WishlistJson } from "@/types/Wishlist.type";
import CustomerModel from "@/common/models/CustomerModel";
import CustomerAddressModel from "@/common/models/CustomerAddressModel";
import { CustomerAddressJson, CustomerJson } from "@/types/Customer.type";
import { ProductJson } from "@/types/Product.type";

type InitialState = {
  addressCheckout: CustomerAddressJson;
  reloadCustomerInfo: boolean;
  customerInfo: CustomerJson;
  wishlist: WishlistJson[];
  wishlistProduct: ProductJson[];
  loadingAddress: boolean;
};
const initialState: InitialState = {
  addressCheckout: CustomerAddressModel.getDefaultData(),
  reloadCustomerInfo: false,
  customerInfo: CustomerModel.getDefaultData(),
  wishlist: [],
  wishlistProduct: [],
  loadingAddress:false
};
const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    //
    setAddressCheckout: (state, action) => {
      state.addressCheckout = action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setWishlist: (state, { payload }: { payload: WishlistJson[] }) => {
      state.wishlist = payload;
    },
    addWishlist: (state, { payload }: { payload: WishlistJson }) => {
      const isProductInWishlist = state.wishlist.some(
        (item) => item.id === payload.id
      );
      if (!isProductInWishlist) {
        state.wishlist = [payload, ...state.wishlist];
      }
    },
    removeWishlist: (state, { payload }: { payload: number }) => {
      const updatedWishlist = state.wishlist.filter(
        (item) => item.item_id !== payload
      );
      state.wishlist = updatedWishlist;
    },
    setWishlistProduct: (state, { payload }: { payload: ProductJson[] }) => {
      state.wishlistProduct = payload;
    },
    addWishlistProduct: (state, { payload }: { payload: ProductJson }) => {
      const isProductInWishlistProduct = state.wishlistProduct.some(
        (item) => item.id === payload.id
      );
      if (!isProductInWishlistProduct) {
        state.wishlistProduct = [...state.wishlistProduct, payload];
      }
    },
    removeWishlistProduct: (state, { payload }: { payload: ProductJson }) => {
      const updatedWishlistProduct = state.wishlistProduct.filter(
        (item) => item.id !== payload.id
      );
      state.wishlistProduct = updatedWishlistProduct;
    },
    setReloadCustomerInfo: (state) => {
      state.reloadCustomerInfo = !state.reloadCustomerInfo;
    },
    setLoadingAddress: (state, { payload }: { payload: boolean }) => {
      state.loadingAddress = payload
      
    }
  },
});

export const {
  setAddressCheckout,
  setCustomerInfo,
  setWishlist,
  addWishlist,
  removeWishlist,
  setWishlistProduct,
  addWishlistProduct,
  removeWishlistProduct,
  setReloadCustomerInfo,
  setLoadingAddress
} = CustomerSlice.actions;

export default CustomerSlice.reducer;
