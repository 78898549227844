"use client"

import { useQuery } from '@tanstack/react-query'
import { getPromotionsFull } from '../api/shop/promotion';
import Cart from '@/common/constants/cart';
import { PromotionGroupType, PromotionJson } from '@/types/Promotion.type';
import { getTimeServer } from '../api/shop/setting';
import SettingApi from '@/common/constants/setting';

const getTypePromotion = (item: PromotionJson, timeNow: number): number => {
  const startDate = Number(item?.start_date);
  const endDate = Number(item?.end_date);
  if (isNaN(startDate) || isNaN(endDate)) {
    // Handle invalid date values if necessary
    throw new Error('Invalid promotion date');
  }
  if (startDate > timeNow) {
    return Cart.PROMOTION_TYPE.PENDING;
  }

  if (startDate <= timeNow && endDate >= timeNow || endDate === 0) {
    return Cart.PROMOTION_TYPE.PROCESSING;
  }

  if (endDate < timeNow) {
    return Cart.PROMOTION_TYPE.DONE;
  }

  return 0; // Default case, though ideally it should not reach here
};
export default function usePromotions() {
  return useQuery({
    queryKey: ['promotions'],
    queryFn: async () => {
      const timeResponse = await getTimeServer(SettingApi.KEY.k_time_server);
      const { data: promotions } = await getPromotionsFull()
      if (timeResponse.data) {
        const timeNow = timeResponse.data.time_server;
        const pros = promotions.items.map((item) => {
          return {
            ...item,
            type_time: getTypePromotion(item, timeNow),
          };
        })
        return pros
      } else {
        return promotions.items
      }
    },
    staleTime: 60 * 1000 * 10,
    placeholderData : []
  })
}
