"use client";
import { SessionProvider } from "next-auth/react";

type Props = {
  children: React.ReactNode;
};

const AuthProviders = ({ children }: Props) => {
  return <SessionProvider
    refetchOnWindowFocus={false}
  >{children}
  </SessionProvider>;
};

export default AuthProviders;
